

















import { Component, Vue } from "vue-property-decorator";
import app from "@/store/app";
import { auth } from "@/utils/LtkAuth0";

@Component
export default class LoggedIn extends Vue {
  public async logout() {
    try {
      app.SET_IS_LOADING(true);
      await auth.logout({
        logoutParams: {
          returnTo: `${window.location.origin}`
        }
      });
      await Vue.$authentication.lambdaAuthorize("", "logout");
      app.SET_IS_LOADING(false);
    } catch (error) {
      app.SET_IS_LOADING(false);
    }
  }
}
